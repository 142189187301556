

$(function () {
    $('#dowebok').fullpage({
        // sectionsColor: ['#1bbc9b', '#4BBFC3', '#7BAABE', '#f90'],
        anchors: ['page1', 'page2', 'page3', 'page4', 'page5', 'page6'],
        menu: '#menu',
        scrollingSpeed: 800,

        afterLoad: function (anchorLink, index) {
            if (index === 6) {
                $('.whiteline').show();
            }
        },
        onLeave: function (index, direction) {
            if (index === 6) {
                $('.whiteline').hide();
            }
        }
    });
});